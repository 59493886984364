<template>
  <div class="col-xl-8">
    <card>
      <template slot="header">
        <h5 class="card-title">Laporan Stok Kategori</h5>
      </template>
      <template slot="default">
        <div class="row">

          <div class="col-12">
            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage">Kembali</p-button>
            </div>
          </div>

          <div class="col-12 mb-3">
            <el-table :data="stockCategoriesSummary" 
                      header-row-class-name="text-primary"
                      v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <el-table-column prop="categoryName" label="Kategori" min-width="200"></el-table-column>
              <el-table-column label="Stok" align="left" width="140">
                <template slot-scope="scope">
                  <div v-if="scope.row.stockCount && scope.row.stockCount !== 0">{{scope.row.stockCount | numberFormat}}{{scope.row.productUnitName ? ` ${scope.row.productUnitName}` : ''}}</div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </template>
    </card>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { Table, TableColumn, Loading } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import arrayHelper from '../../../helpers/arrayHelper'
  import filterHelper from '../../../helpers/filterHelper'

  Vue.use(Loading)
  
  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data () {
      return {
        hasPrevRoute: false,
        isStockCategoriesSummaryLoading: false,
        isReferencesLoading: false,
        stockCategoriesSummary: [],
        selects: {
          productCategories: [],
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.fetchData()
      },
      fetchData () {
        const fetchProductCategories = () => {
          return new Promise((resolve, reject) => {
            this.isReferencesLoading = true

            this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_CATEGORIES)
              .then(response => {
                let categories = response.data.isSuccess ? response.data.productCategories : []
                categories.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
                this.selects.productCategories = categories

                this.isReferencesLoading = false
                resolve()
              })
              .catch(() => {
                this.isReferencesLoading = false
                reject()
              })
          })
        }

        const fetchStockCategoriesSummary = () => {
          this.isStockCategoriesSummaryLoading = true
          
          this.axios.get(urlConstant.API_URL_REPORTS_PRODUCTS_STOCK_CATS_SUM)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isStockCategoriesSummaryLoading = false
                return
              }

              this.stockCategoriesSummary = this.selects.productCategories.map(x => ({
                categoryId: x.id,
                categoryName: x.name,
                stockCount: 0,
                productUnitName: '',
              }))

              for (const productCategoryCount of response.data.productCategoryCounts) {
                let stockCategory = this.stockCategoriesSummary.find(x => x.categoryId == productCategoryCount.productCategoryId)
                
                if (stockCategory) {
                  stockCategory.stockCount = productCategoryCount.totalProductUnitCount
                  stockCategory.productUnitName = productCategoryCount.productUnitName
                }
              }

              // Sort
              this.stockCategoriesSummary.sort((a, b) => b.stockCount - a.stockCount || arrayHelper.stringCompare(a.name, b.name))
              
              this.isStockCategoriesSummaryLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isStockCategoriesSummaryLoading = false
            })
        }

        fetchProductCategories()
          .then(fetchStockCategoriesSummary)
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(`${urlConstant.WEB_URL_REPORTS}?tab=products`)
        }
      },
    },
    computed: {
      isLoading () {
        return this.isStockCategoriesSummaryLoading || this.isReferencesLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
  }
</script>
<style scoped lang="scss">
</style>
