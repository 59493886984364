<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Buat Baki Penjualan</h5>
          <h5 class="card-title" v-if="isView">Detail Baki Penjualan</h5>
          <h5 class="card-title" v-if="isEdit">Ubah Baki Penjualan</h5>
        </template>
        <template slot="default">
          <h6>Detail</h6>
          <div class="pt-1" v-if="isView">
            <div class="alert" :class="getStatusAlertColor(model.isActive)">
              Status: <strong class="text-uppercase">{{getStatusLocal(model.isActive)}}</strong>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
          </div>

          <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Nama baki" 
                            v-model="model.name"
                            :disabled="isView"
                            required
                            v-validate="'required|max:150'"
                            data-vv-name="Nama baki"
                            :error="getError('Nama baki')"></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Kode baki" 
                            v-model="model.code"
                            v-if="isView || isEdit"
                            disabled
                            required></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Penanggung jawab" 
                            v-if="isNew || isEdit"
                            required 
                            :disabled="isView"
                            :error="getError('Penanggung jawab')">
                    <el-select class="kf select-default" 
                              v-model="model.trayOwnerId"
                              v-validate="'required'"
                              :disabled="isView"
                              data-vv-name="Penanggung jawab"
                              placeholder="Cari pegawai"
                              @change="onEmployeeChange(model)"
                              filterable
                              remote
                              :clearable="selects.initialEmployees[0] && model.trayOwnerId !== selects.initialEmployees[0].id"
                              :remote-method="fetchEmployees"
                              :loading="isFetchingEmployees">
                      <el-option v-for="option in selects.employees"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.fullName"
                                :key="option.id">
                                <div class="d-flex justify-content-between">
                                  <div v-html="$options.filters.highlight(option.fullName, employeeSearchText)"></div>
                                  <div class="ml-3 small text-muted" v-html="getEmployeeDescription(option, employeeSearchText)"></div>
                                </div>
                      </el-option>
                      <template slot="empty">
                        <p class="el-select-dropdown__empty">
                          Tidak ada data
                        </p>
                      </template>
                    </el-select>
                  </fg-input>
                  <fg-input label="Penanggung jawab" 
                            v-model="model.trayOwner.fullName"
                            v-if="isView"
                            disabled
                            required></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Status" :disabled="isView" required>
                    <div class="d-flex flex-wrap align-items-center form-control no-border p-0">
                      <p-switch :disabled="isView" v-model="model.isActive" type="success" class="mb-0"></p-switch>
                      <span class="ml-2">{{getStatusLocal(model.isActive)}}</span>
                    </div>
                  </fg-input>
                </div>
              </div>

              <h6 class="mt-3">Produk</h6>

              <div class="section-items" :class="{'section-items-edit-mode': isNew || isEdit}">
                <el-table :data="model.items" 
                          header-row-class-name="text-primary" 
                          class="table-compact"
                          ref="itemsTable"
                          @current-change="onTableSelectionChange"
                          :highlight-current-row="isNew || isEdit">
                  <el-table-column label="No." type="index" width="50">
                    <template slot-scope="scope">
                      <div class="cell-index">{{getRowNumber(scope)}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Kode" width="190">
                    <template slot-scope="scope">
                      <div v-if="!scope.row.productId">
                        <fg-input required 
                                  class="mb-0"
                                  :disabled="isView">
                          <el-select class="kf select-default" 
                                      v-model="scope.row.productId"
                                      @change="onProductChange(scope.row, scope.$index)"
                                      :disabled="isView"
                                      placeholder="Tambah produk"
                                      filterable
                                      remote
                                      :remote-method="fetchProducts"
                                      :loading="isFetchingProducts">
                            <el-option v-for="option in selects.products"
                                        class="kf select-default"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                              <div class="d-flex justify-content-between">
                                <div>{{ option.code }}</div>
                                <div class="ml-3 small text-muted">{{ option.name }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>
                      <div v-else>{{scope.row.product.code}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="product.name" label="Nama" min-width="220"></el-table-column>
                  <el-table-column prop="product.categoryName" label="Kategori" width="150"></el-table-column>
                  <el-table-column label="Karat" width="110">
                    <template slot-scope="scope">
                      <el-tooltip v-if="!!scope.row.productId" placement="top" :content="caratTooltip(scope.row)">
                        <div>
                          <span>{{scope.row.product.carat}}k</span>
                          <span v-if="scope.row.product.caratPercentage"> / {{scope.row.product.caratPercentage}}%</span>
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column label="Berat" width="105">
                    <template slot-scope="scope">
                      <el-tooltip v-if="!!scope.row.productId" placement="top" :content="weightTooltip(scope.row)">
                        <div>
                          <span>{{scope.row.product.weight}}gr</span>
                          <span v-if="scope.row.product.size"> / {{scope.row.product.size}}</span>
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column label="Stok" width="115">
                    <template slot-scope="scope">
                      <div v-if="!!scope.row.productId" class="cell-edit">
                        <fg-input type="number" 
                                  onwheel="this.blur()"
                                  v-model.number="scope.row.unitAmount"
                                  :disabled="isView"
                                  v-validate="`required|min_value:1|max_value:${scope.row.product.unitAmount}`"
                                  required
                                  :data-vv-name="`Stok-${scope.$index}`"
                                  data-vv-as="Stok"
                                  :error="getError(`Stok-${scope.$index}`)"
                                  hide-error-message
                                  class="mb-0">
                          <template slot="addonRight">{{scope.row.unitName}}</template>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.unitAmount | numberFormat}}{{(scope.row.unitAmount || scope.row.unitAmount === 0) && scope.row.unitName ? ` ${scope.row.unitName}` : ''}}</div>
                      <div class="cell-error text-danger" v-if="(isNew || isEdit) && getError(`Stok-${scope.$index}`)">
                        {{getError(`Stok-${scope.$index}`)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column class-name="action-buttons td-actions" align="right" width="50" v-if="isNew || isEdit">
                    <template slot-scope="scope">
                      <div v-if="!!scope.row.productId">
                        <el-tooltip placement="top" content="Keluarkan Produk">
                          <p-button type="danger" size="sm" icon class="rounded" @click.stop="removeItem(scope.row, scope.$index)">
                            <i class="fa fa-times"></i>
                          </p-button>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </form>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center mt-3">
              <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
              <p-button outline round @click="goToPrevPage()" v-if="isNew || isEdit">Batal</p-button>
              <p-button type="primary" round @click="validate()" v-if="isNew">Buat Baki Penjualan</p-button>
              <p-button type="primary" round @click="validate()" v-if="isEdit">Simpan Perubahan</p-button>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { Select, Option, Loading, Table, TableColumn, Tooltip } from 'element-ui'
  import { Card } from '../../UIComponents'
  import PSwitch from '../../UIComponents/Switch.vue'
  import * as urlConstant from '../../../constants/urlConstant'
  import filterHelper from '../../../helpers/filterHelper'
  import * as employeeSearchQueryOrderEnum from '../../../enums/employeeSearchQueryOrderEnum'
  import alertHelper from '../../../helpers/alertHelper'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'

  Vue.use(Loading)

  const initialTrayItem = {
    id: null,
    productId: null,
    product: {
      id: null,
      name: '',
      code: '',
      materialName: '',
      categoryName: '',
      carat: null,
      caratPercentage: null,
      weight: null,
      size: null,
      unitId: null,
      unitName: '',
      unitAmount: null,
    },
    unitId: null,
    unitName: '',
    unitAmount: null,
  }

  export default {
    components: {
      Card,
      [Option.name]: Option,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
      PSwitch
    },
    data () {
      return {
        isNew: false,
        isView: false,
        isEdit: false,
        isFetchingEmployees: false,
        isFetchingProducts: false,
        isSaveLoading: false,
        isTrayLoading: false,
        isTrayProductsLoading: false,
        selectedItem: null,
        hasPrevRoute: false,
        employeeSearchText: '',
        initialModel: {},
        model: {
          id: null,
          name: '',
          code: '',
          isActive: true,
          trayOwnerId: '',
          trayOwner: {
            id: null,
            fullName: '',
            email: '',
          },
          items: [_.cloneDeep(initialTrayItem)],
          itemsCount: null
        },
        selects: {
          employees: [],
          initialEmployees: [],
          products: [],
          initialProducts: [],
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false
        this.isEdit = false

        if (!this.$route.params.traySlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else if (this.$route.path.includes('/edit/')) {
          this.isEdit = true
        }
        else {
          this.isNew = true
        }

        if (this.isNew) {
          return
        }

        const trayId = base64url.decode(this.$route.params.traySlug)

        this.isTrayLoading = true
        this.axios.get(`${urlConstant.API_URL_TRAYS}/${trayId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isTrayLoading = false
              return
            }

            if (!response.data.tray) {
              alertHelper.basic('Baki tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isTrayLoading = false
              return
            }

            this.initialModel = _.cloneDeep(response.data.tray)
            this.model = response.data.tray

            if (this.isEdit) {
              this.model.items.unshift(_.cloneDeep(initialTrayItem))
            }
            
            // Initial employee
            this.selects.initialEmployees = [_.cloneDeep(this.model.trayOwner)]
            this.selects.employees = _.cloneDeep(this.selects.initialEmployees)

            this.isTrayLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isTrayLoading = false
          })
      },
      fetchEmployees (query) {
        this.employeeSearchText = query

        if (!query) {
          this.selects.employees = _.cloneDeep(this.selects.initialEmployees)
          return
        }

        const params = {
          name: query, 
          email: query,
          useOrOperator: true,
          order: employeeSearchQueryOrderEnum.NameAsc,
          pageSize: 10,
          pageNumber: 1
        }

        this.isFetchingEmployees = true
        this.axios.get(urlConstant.API_URL_EMPLOYEES, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.employees = []
              this.isFetchingEmployees = false
              return
            }
            
            this.selects.employees = response.data.employees
            this.isFetchingEmployees = false
          })
          .catch(() => {
            this.selects.employees = []
            this.isFetchingEmployees = false
          })
      },
      onEmployeeChange (model) {
        if (!model.trayOwnerId) {
          this.selects.employees = _.cloneDeep(this.selects.initialEmployees)
          model.trayOwnerId = this.selects.employees[0].id
          return
        }

        const employee = this.selects.employees.find(x => x.id === model.trayOwnerId)
        
        model.trayOwner = employee
      },
      getEmployeeDescription (employee, searchQuery) {
        const regex = new RegExp(searchQuery, 'i')

        const defaultResult = employee.email
          ? `<i class="fa fa-envelope-o"></i>&nbsp;${filterHelper.highlight(employee.email, searchQuery)}`
          : ''

        if (regex.test(employee.email)) {
          return defaultResult
        }

        return defaultResult
      },
      fetchProducts (query) {
        if (!query) {
          this.selects.products = _.cloneDeep(this.selects.initialProducts)
          return
        }
        
        const params = { 
          code: query,
          pageSize: 10,
          pageNumber: 1
        }
        
        this.isFetchingProducts = true
        this.axios.get(urlConstant.API_URL_PRODUCTS_STOCKS, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.products = []
              this.isFetchingProducts = false
              return
            }
            
            this.selects.products = response.data.products
            this.isFetchingProducts = false
          })
          .catch(() => {
            this.selects.products = []
            this.isFetchingProducts = false
          })
      },
      onProductChange (trayItem, rowIndex) {
        const product = trayItem.productId 
          ? this.selects.products.find(p => p.id === trayItem.productId) 
          : null

        if (product && this.model.items.filter(i => product.id === i.productId).length > 1) {
          const title = 'Gagal menambah produk!'
          const contentHtml = `Produk <strong>${product.code} - ${product.name}</strong> sudah ada di dalam baki ini.`

          alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.warning)

          trayItem.productId = null
          this.selects.products = []
          return
        }

        const successAction = () => {
          trayItem.product = product
          trayItem.unitId = product ? product.unitId : null
          trayItem.unitName = product ? product.unitName : ''
          trayItem.unitAmount = 1

          if (rowIndex === 0) {
            this.model.items.unshift(_.cloneDeep(initialTrayItem))
            this.errors.clear()
          }
          
          this.selects.products = []
        }
      
        // Check if the product was never been added into tray before
        this.isTrayProductsLoading = true
        this.axios.get(urlConstant.API_URL_PRODUCTS_TRAYS, { params: { 
          code: product.code,
          pageSize: 1,
          pageNumber: 1
        }})
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isTrayProductsLoading = false
              return
            }

            if (response.data.totalCount > 0) {
              const firstTrayProduct = response.data.trayProducts[0]

              const title = 'Gagal menambah produk!'
              const contentHtml = `Produk <strong>${product.code} - ${product.name}</strong> sudah terdaftar di dalam baki <strong>${firstTrayProduct.trayCode} - ${firstTrayProduct.trayName}</strong>.`

              alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.warning)

              trayItem.productId = null
              this.selects.products = []
            } else {
              successAction()
            }

            this.isTrayProductsLoading = false
          })
          .catch(() => {
            errorHandlerHelper.handleGeneralApiError()
            this.isTrayProductsLoading = false
          })
      },
      onTableSelectionChange (value) {
        this.selectedItem = value
      },
      removeItem (row, rowIndex) {
        const contentHtml = `Apakah kamu yakin ingin mengeluarkan produk <strong>${row.product.name}</strong> dari baki?`
        const onConfirm = () => {
          this.model.items.splice(rowIndex, 1)
          this.errors.clear()
        }

        alertHelper.confirmHtml(null, contentHtml, alertHelper.typeEnums.warning, 'Keluarkan Produk', 'Batal', onConfirm)
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_TRAYS)
        }
      },
      getStatusAlertColor (isActive) {
        return isActive ? 'alert-success' : 'alert-muted'
      },
      getStatusLocal (isActive) {
        return isActive ? 'Aktif' : 'Tidak aktif'
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveTray()
        })
      },
      saveTray () {
        this.isSaveLoading = true

        // Remove empty objects
        const modelCopy = _.cloneDeep(this.model)
        modelCopy.items = modelCopy.items.filter(i => i.productId)

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_TRAYS, modelCopy)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.tray)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        } else {
          this.axios.post(`${urlConstant.API_URL_TRAYS}/${modelCopy.id}`, modelCopy)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.tray)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (tray) {
        const title = this.isNew ? 'Baki penjualan berhasil dibuat!' : 'Baki penjualan berhasil diubah!'
        const content = `Kode baki: ${tray.code}`

        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || !apiResponse.apiError) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_PRODUCTTRAY_PRODUCT_ALREADY_REGISTERED_IN_TRAY) {
          showAlert('Jenis produk yang sama sudah terdaftar di salah satu baki, mohon cek kembali produk di baki dengan kode produk yang sama.')
          return
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_PRODUCTTRAY_INVALID_TRAY_STOCK_QUANTITY) {
          showAlert('Jumlah stok untuk produk yang akan ditambahkan ke baki melebihi stok produk yang tersedia, mohon cek kembali jumlah stok produk yang akan dimasukkan ke baki.')
          return
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_PRODUCTTRAY_DUPLICATE_TRAY_ITEM_PRODUCT_ID) {
          showAlert('Jenis produk yang sama sudah terdaftar di baki ini, mohon cek kembali produk-produk di baki ini.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      caratTooltip (row) {
        if (!row || !row.product.carat) {
          return ''
        }

        let output = `Karat: ${row.product.carat}k`

        if (row.product.caratPercentage) {
          output += ` / Persentase Karat: ${row.product.caratPercentage}%`
        }

        return output
      },
      weightTooltip (row) {
        if (!row || !row.product.weight) {
          return ''
        }

        let output = `Berat: ${row.product.weight}gr`

        if (row.product.size) {
          output += ` / Ukuran: ${row.product.size}`
        }

        return output
      },
      getRowNumber (scope) {
        return this.isView ? scope.$index + 1 : scope.$index
      },
    },
    computed: {
      isLoading () {
        return this.isTrayLoading || this.isSaveLoading || this.isTrayProductsLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
  }
</script>
<style scoped lang="scss">
  .section-items {
    table {
      tbody {
        tr {
          .cell-edit, .cell-edit-info {
            display: none;
          }
          .cell-error {
            font-size: 80%;
            padding-left: 0;
            text-align: left;
            display: block;
          }
        }
      }
    }

    &.section-items-edit-mode {
      table {
        tbody {
          tr {
            &.current-row {
              .cell-edit {
                display: inline;
              }
              .cell-view, .cell-edit-info {
                display: none;
              }
              .cell-error {
                padding-left: 11px;
              }
              .cell-index {
                margin-top: 8px;
              }
            }

            &:first-child {
              .cell-edit {
                display: inline;
              }
              .cell-view {
                display: none;
              }
              .cell-index {
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
